define(function (require, exports, module) {
    var menu = function (config) {
        var opt = _.clone(esapp.ui.defaultConfig)
        _.merge(opt, {
            // width: '50px',
            // top: '52px',
            color: '',
            type: $('[esapp-menu]'),
            menuList: {
                items: [
                    // ,
                    // , {
                    //     text: '放箱员操作',
                    //     href: 'supplier'
                    // }
                ]
            }
        }, config)

        opt.init = function () {
            var _ = this;
            _.$element = _.type;
            _.$element_ul = _.$element.find("ul");
            _.$element_li = _.$element.find("li");
            _.$container = _.$element.next();
            _.layout();
            _.tpl();
            _.highlight();
            _.showSecondMenu();
            opt.showCallBack && opt.showCallBack()
        }
        opt.layout = function () {
            var _ = this;
            _.$element.css({});

            _.$container.css({
                marginLeft: _.width
            })
        }

        opt.tpl = function () {
            // var menu_tpl = require('text!../../template/menu.tpl')
            var self = this
            $.get('../template/menu.tpl', function (res) {
                var compiled = _.template(res);
                self.$element.html(compiled(self.menuList));
                // this.$element.find('ul').css({
                //     width: parseInt(this.width) * 2 + "px"
                // });
                self.$element.find('li').css({
                    width: self.width
                });
                // var homepage = self.chooseHomePage()
                var homepage = "control-center";
                Path.root(homepage);
                Path.listen();
                $('#shell-tabs li.active').find('.shell-tab-remove').remove();
            })


        }

        opt.highlight = function (currentPage) {
            if (currentPage) {
                this.currentPage = currentPage;
            }

            // if (_.find(this.menuList.items, ['href', this.currentPage.substring(1)]) && this.currentPage !== '#home') {
            if (this.currentPage !== '#home') {
                // this.$element.children().siblings().removeClass('highlight')
                $('#shell_menu li.active').removeClass('active')
                $('#shell_menu li.open').removeClass('open')
                var target = this.$element.find('.shell_menu_column [esapp-href="' + this.currentPage.substring(1) + '"]')
                $("#shell-contents div[role=tabpanel]").removeClass('active')
                var id = this.currentPage;
                // if(this.currentPage.indexOf('order-details') >= 0 ) id = "#order-details_" + id.split(':')[1].split('|')[0];
                // if(this.currentPage.indexOf('customer-change-detail') >= 0 ) id = "#customer-change-detail_" + id.split(':')[1].split('|')[0];
                $("#shell-contents div" + id).addClass('active');
                if (target.length > 0 && target[0].tagName !== "LI") {
                    target.parent().addClass('open')
                } else {
                    target.addClass('open')
                    target.parent().parent().addClass('active')
                }
            } else {
                // this.$element.children().siblings().removeClass('highlight')
            }
        }

        opt.showSecondMenu = function () {
            var _ = this;
            _.$element.delegate('.shell_menu_column', 'click', function () {
                // $(this).siblings().removeClass('active highlight');

                if (!$(this).hasClass('active')) {
                    _.$element.find('.shell_menu_column').removeClass('active highlight');
                    _.$element.find('span.open').removeClass('open')
                    $(this).addClass('active highlight').find('a>span.arrow').addClass('open');
                } else {
                    // $(this).removeClass('active highlight');
                }
            })
        }

        opt.init();
        return opt
    }
    return menu
})
